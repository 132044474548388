@import "variables";

#homepage-blog{
	padding-left: 15px;
	padding-right: 15px;
}

#homepage-blog ul{
	list-style-type: none;
	padding-left: 0;
}

@media(max-width:460px){
	#homepage-blog li{
		width: 100% !important;
	}
}

@media(max-width:768px){
	#homepage-blog li:nth-child(3){
		clear: both;
	}
}

#homepage-blog .item{
	background: theme-color(white);
	margin-bottom: 30px;
}
.content-item{
	padding: 20px;
}
.product-name{
	font-size: 16px;
	color: #333;
	font-family: filson-pro, sans-serif;
    font-weight: 700;
	margin-bottom: 10px;
}
.post-descr a{
	color: lighten(theme-color(black),30%);
}
.date-added{
	color: lighten(theme-color(black),30%);
	font-size: 13px;
	font-style: italic;
	margin-bottom: 10px;
}

.btn-all-blog{
	background: theme-color(secondary);
	padding: 15px 30px;
	display: inline-block;
	color: theme-color(white);
	font-size: 14px;
	text-transform: uppercase;
	border-radius: 25px;
	-moz-transition:all linear .2s;
	-ms-transition:all linear .2s;
	-o-transition:all linear .2s;
	-webkit-transition:all linear .2s;
	transition:all linear .2s;
}
.btn-all-blog:hover,
.btn-all-blog:focus{
	color: theme-color(white);
	background: darken(theme-color(secondary),5%);
}
